/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import siteConfig from '../../../data/SiteConfig';

export default function content() {
  return (
    <div className="site-wrap">
      <div className="site-container">
        <div className="site-content site-content-posts site-content-posts-large">
          <article>
            <time dateTime="2021-02-05T12:49">Updated: February 05, 2021</time>
            <h2>EULA</h2>
            <blockquote>
              <p>
                This Limited Use Software License Agreement for{' '}
                <strong>DanfeBooks</strong>® (this "Agreement") is a legal
                agreement between you, the end-user, and OPTIMUM FUTURIST TECH
                Pvt. Ltd. ("Licensor"). This Agreement incorporates by reference
                the{' '}
                <a href="/privacy-policy?utm_source=EULA&amp;utm_campaign=SignUp">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/terms-of-service?utm_source=EULA&amp;utm_campaign=SignUp">
                  Terms of Use Policy
                </a>
                , each posted on{' '}
                <a href={siteConfig.siteUrl}>{siteConfig.siteUrl}</a>, as these
                policies and terms may be modified by Licensor from time to
                time.
              </p>
            </blockquote>
            <p>
              THE APP DANFEBOOKS® IS REFERRED TO HEREIN AS THE “SOFTWARE”. YOU
              MAY NOT ACCESS THE SOFTWARE UNTIL AFTER YOU HAVE OPENED AN ACCOUNT
              (“YOUR ACCOUNT”) ON THE WEB SITE ON WHICH THE SOFTWARE IS LOCATED.
              BY CONTINUING THE ACCOUNT SETUP AND USAGE OF THE "SOFTWARE", BY
              LOADING OR RUNNING THE SOFTWARE IN ANY INTERNET CONNECTED DEVICE
              YOU ARE AGREEING TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
              AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU
              ARE NOT PERMITTED TO USE THE SOFTWARE. IN NO EVENT WILL YOU BE
              ENTITLED TO ANY REFUND OR SUBSCRIPTION FEES OR DAMAGE CLAIM IN THE
              EVENT THAT YOU CHOOSE THE “I DECLINE” BUTTON DURING INITIAL
              ACCOUNT SETUP. The then-current version of this Agreement can be
              found at <a href={siteConfig.siteUrl}>{siteConfig.siteUrl}</a>. If
              you have any questions regarding these terms and conditions,
              please contact customer service at support@danfebooks.com
            </p>

            <ol>
              <li>
                Grant of License. Subject to the terms and provisions of this
                Agreement and so long as you comply fully at all times with this
                Agreement, Licensor grants to you the non-exclusive, revocable
                and limited right to use the Software in web version form only
                by loading the Software into any web browser and mobile browser
                only and you may use the Software as permitted in Section 4
                below. The term "Software" includes all elements of the
                Software, including, without limitation, data files, screen
                displays, other written, electronic or on-line materials or
                documentation, and any all copies of such Software and its
                materials. You hereby acknowledge and agree you are not
                receiving any ownership or proprietary right, title, or interest
                in or to the Software or the copyrights, trademarks, patents, or
                other rights related thereto. You agree that the Software will
                not be shipped, transferred, or exported into any other country
                than NEPAL by you or anyone at your direction, and that you will
                not utilize and will not authorize anyone to utilize, in any
                other manner, the Software in violation of any applicable law.
                The Software shall not be downloaded or otherwise exported or
                re-exported into any country. In exercising your limited rights
                hereunder, you shall comply at all times with all applicable
                laws, regulations, ordinances, and statutes. Licensor reserves
                all rights not granted in this Agreement, including, without
                limitation, all rights to Licensor's trademarks including
                without limitation the trademark danfebooks® and you agree you
                are not receiving any right to use any Licensor trademark.
              </li>

              <li>
                Prohibitions. You, whether directly or indirectly, shall not do
                any of the following acts:
                <ol>
                  <li>modify the Software;</li>
                  <li>alter the Software;</li>
                  <li>rent the Software;</li>
                  <li>sell the Software;</li>
                  <li>lease or lend the Software;</li>
                  <li>offer the Software on a "pay-per-play" basis;</li>
                  <li>
                    in any manner and through any medium whatsoever,
                    commercially exploit the Software or use the Software for
                    any hacking, tampering purpose;
                  </li>
                  <li>
                    disassemble, reverse engineer, or decompile the Software;
                  </li>
                  <li>translate the Software;</li>
                  <li>
                    create or develop extra or add on levels for the Software,
                    excepts as expressly permitted under Section 7 below;
                  </li>
                  <li>
                    reproduce or copy the Software, except as expressly
                    permitted under Section 4 below;
                  </li>
                  <li>
                    prepare or develop derivative works based upon the Software;
                  </li>
                  <li>
                    remove or alter any legal notices or other markings or
                    legends, such as trademark and copyright notices, affixed on
                    or within the Software;
                  </li>
                  <li>
                    remove, alter, modify, disable, or reduce any of the
                    anti-piracy measures or components contained in the
                    Software;
                  </li>
                  <li>
                    bundle or package the Software with any other software,
                    hardware, or anything else, except as expressly permitted
                    under Section 4 below in regard to mounting the Software on
                    magazine covers; or
                  </li>
                </ol>
              </li>
              <li>
                Prohibition against Cheat Programs. Any attempt by you, either
                directly or indirectly, to circumvent or bypass any element of
                the Software to gain any advantage in premium features of the
                Software is a material breach of this Agreement. It is a
                material breach of this Agreement for you, whether directly or
                indirectly, to create, develop, copy, reproduce, distribute, or
                otherwise make any use of any software program or any
                modification to the Software ("Cheat Program") itself that
                enables or allows the user thereof to obtain an advantage.
                Hacking into the executable of the Software, modification of the
                Software, or any other use of the Software in connection with
                the creation, development, or use of any such unauthorized Cheat
                Program is a material breach of this Agreement. Cheat Programs
                include, but are not limited to, programs that allow users to
                user any unauthorized area of Software; or any other program or
                modification that functions in a similar capacity or allows any
                prohibited conduct. In the event you breach this Section or
                otherwise breach this Agreement, the license granted to you
                herein and this Agreement automatically shall terminate, without
                notice, and you shall have no right to make any use of the
                Software.
              </li>
              <li>
                Permitted Sharing. Subject to the terms and conditions of this
                Agreement, you may share one (1) or many links of the Software
                website per computer and, in exercising your limited rights
                hereunder, you shall comply at all times with all applicable
                laws, regulations, ordinances, and statutes. Licensor reserves
                all rights not granted in this Agreement. You shall not Share
                the Software commercially unless you first enter into a separate
                written contract with Licensor, on terms and conditions
                determined in Licensor's sole discretion, and only upon your
                receipt of a written agreement executed by an authorized officer
                of Licensor.
              </li>
              <li>
                Intellectual Property Rights. Licensor owns 100% of the Software
                including all elements thereof. The Software and all copyrights,
                trademarks, and all other conceivable intellectual property
                rights related to the Software are owned by Licensor and are
                protected by Nepal Government copyright laws, international
                treaty provisions, and all applicable law. You must treat the
                Software like any other copyrighted material, as required by
                applicable law. You agree to use your best efforts to see that
                any user of the Software licensed hereunder complies with this
                Agreement. You agree that you are receiving a copy of the
                Software by license only and not by sale. This Section shall
                survive the cancellation or termination of this Agreement.
              </li>
              <li>
                Account Termination and Information Collection; Passwords. For
                standard users, Your Account may be terminated by Licensor
                without notice at any time following any six (6) month period of
                inactivity. When you purchase a paid subscription, a term length
                is specified in the subscription. Absent notice to the contrary,
                paid subscriptions are active for the length of the
                subscription’s term. You may have three (3) Accounts at any
                time, and Licensor may terminate without notice Your Account(s)
                in the event you open more than three (3) Account(s). Any
                violation of the Terms of Use or any prohibition herein is cause
                for termination of an Account. For paid subscriptions, no refund
                will be granted; no online time or other credits will be
                credited to you or converted to cash or other form of
                reimbursement, and you will have no further access to Your
                Account or entitlements associated with Your Account.
              </li>
              <li>
                In no event shall Licensor be held responsible for any damage in
                the event your passwords are disclosed, including but limited to
                any damage that occurs to Your Account, your characters or your
                scores. Further, Licensor shall not be liable for any financial
                or emotional damage or distress you may suffer and/or for
                subsequent loss or damage to Your Account, characters, scores as
                a result of the disclosure of your passwords to a third party.
                You shall not disclose your passwords to any third party or
                allow anyone to use your password to access Your Account, or to
                use the Software. It is also prohibited to obtain, attempt to
                obtain, use, or attempt to use the password of anyone else. SUCH
                DISCLOSURE AND/OR USE IS A BREACH OF THIS AGREEMENT. WE NEVER
                ASK YOU FOR YOUR PASSWORD BY TELEPHONE OR E-MAIL, AND YOU SHOULD
                NOT DISCLOSE IT THIS WAY IF SOMEONE ASKS YOU TO DO SO.
              </li>
              <li>
                Disclaimer of Warranties. LICENSOR DISCLAIMS ALL WARRANTIES,
                WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                IMPLIED WARRANTIES OF NON INFRINGEMENT, TITLE, MERCHANTABILITY,
                AND FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE
                SOFTWARE, CUSTOM, TRADE, QUIET ENJOYMENT, ACCURACY OF
                INFORMATIONAL CONTENT, OR SYSTEM INTEGRATION, OR ANY WARRANTIES
                ARISING UNDER ANY STATUTE, COURSE OF DEALING, OR OTHER LEGAL
                REQUIREMENT OR OTHERWISE. THE SOFTWARE IS PROVIDED "AS IS" AND
                WITHOUT WARRANTY. LICENSOR DOES NOT WARRANT OR REPRESENT THAT
                THE SOFTWARE OR THE OPERATION OF THE SOFTWARE WILL BE
                UNINTERRUPTED OR ERROR-FREE OR THAT THE SOFTWARE WILL MEET YOUR
                SPECIFIC OR SPECIAL REQUIREMENTS. In addition, you acknowledge
                that LICENSOR is not responsible for the Internet or whether it
                should continue to exist in its present form or whether or not a
                government or governmental agency, either foreign or domestic,
                will control, regulate or disband the Internet. Use of the
                Internet is at your sole risk. ADDITIONAL STATEMENTS, WHETHER
                ORAL OR WRITTEN, DO NOT CONSTITUTE WARRANTIES BY LICENSOR AND
                SHOULD NOT BE RELIED UPON. THIS SECTION SHALL SURVIVE THE
                CANCELLATION OR TERMINATION OF THIS AGREEMENT.
              </li>
              <li>
                Governing Law, Venue, Indemnity, and Liability Limitation. This
                Agreement shall be construed in accordance with and governed by
                the applicable laws of the State 3 (but excluding conflicts of
                laws principles) and applicable NEPAL GOVERNMENT law. Except as
                set forth below, exclusive venue for all litigation regarding
                this Agreement shall be in KATHMANDU DISTRICT, STATE 3, NEPAL,
                and you agree to submit to the jurisdiction of the federal and
                state courts in KATHMANDU DISTRICT, STATE 3, NEPAL, for any such
                litigation. You hereby agree to indemnify, defend and hold
                harmless Licensor, its affiliates and their respective officers,
                employees, directors, agents, licensees (excluding you),
                sub-licensees (excluding you), successors, and assigns from and
                against all losses, lawsuits, damages, causes of action, and
                claims relating to and/or arising from: (i) your breach of this
                Agreement; (ii) your distribution or other use of the Software;
                and/or (iii) your acts and omissions to act in using the
                Software pursuant to the terms of the Agreement. You agree that
                your unauthorized use of the Software, or any part thereof,
                immediately and irreparably may damage Licensor such that
                Licensor could not be compensated adequately solely by a
                monetary award, and in such event, and at Licensor's option,
                Licensor shall be entitled to an injunctive order, in addition
                to all other available remedies, including a monetary award, to
                prohibit such unauthorized use without the necessity of Licensor
                proving damages or posting bond or other security. LICENSOR, ITS
                AFFILIATES AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, DIRECTORS,
                SHAREHOLDERS, REPRESENTATIVES, AGENTS, LICENSEES (EXCLUDING
                YOU), SUBLICENSEES (EXCLUDING YOU), SUCCESSORS, AND ASSIGNS
                SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR LOSS OF DATA OR
                RECORDS, DAMAGE TO ANY SYSTEMS, HARDWARE OR SOFTWARE, LOSS OF
                PROFITS, LOST SAVINGS, ACTUAL, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, INDIRECT, OR PUNITIVE DAMAGES, THIRD PARTY
                CLAIMS, OR ANY OTHER DAMAGES ARISING FROM ANY ALLEGED CLAIM FOR
                BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, STRICT
                PRODUCT LIABILITY, OR OTHER LEGAL THEORY, EVEN IF LICENSOR OR
                ITS AGENTS HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH
                DAMAGES, OR EVEN IF LICENSOR OR ITS AGENTS RECEIVE NOTICE OF THE
                POSSIBILITY OF ANY SUCH DAMAGES, OR EVEN IF SUCH DAMAGES ARE
                FORESEEABLE, OR LIABLE FOR ANY CLAIM BY ANY OTHER PARTY. Some
                jurisdictions do not allow the exclusion or limitation of
                incidental or consequential damages, so the above limitation or
                exclusion may not apply to you. This Section shall survive the
                cancellation or termination of this Agreement. IN NO EVENT SHALL
                THE ENTIRE LIABILITY OF LICENSOR ARISING FROM OR IN ANY WAY
                RELATED TO THE SOFTWARE OR ANY PART THEREOF, OR THIS AGREEMENT,
                EXCEED IN THE AGGREGATE THE FEES PAID BY YOU IN RESPECT OF YOUR
                USE OF THE SERVICES OVER THE PRIOR THREE (3) MONTH PERIOD.
              </li>
              <li>
                General Provisions. Neither this Agreement nor any part or
                portion hereof shall be assigned or sublicensed by you. Licensor
                may assign its rights under this Agreement in their sole
                discretion. Should any provision of this Agreement be held to be
                void, invalid, unenforceable, or illegal by a court of competent
                jurisdiction, the validity and enforceability of the other
                provisions shall not be affected thereby. If any provision is
                determined to be unenforceable by a court of competent
                jurisdiction, you agree to a modification of such provision to
                provide for enforcement of the provision's intent, to the extent
                permitted by applicable law. Failure of Licensor to enforce any
                provision of this Agreement shall not constitute or be construed
                as a waiver of such provision or of the right to enforce such
                provision. IMMEDIATELY UPON YOUR FAILURE TO COMPLY WITH THIS
                AGREEMENT OR YOUR BREACH OF ANY TERM OR PROVISION OF THIS
                AGREEMENT, THIS AGREEMENT AND THE LICENSE GRANTED TO YOU HEREIN
                AUTOMATICALLY SHALL TERMINATE, WITHOUT NOTICE, AND LICENSOR MAY
                PURSUE ALL RELIEF AND REMEDIES AGAINST YOU THAT ARE AVAILABLE
                UNDER APPLICABLE LAW AND/OR THIS AGREEMENT. Immediately upon the
                termination of this Agreement, any and all rights you are
                granted hereunder shall terminate, you shall have no right to
                use the Software, in any manner, you shall cease any and all use
                of the Software, and you shall remove immediately the Software
                from all hard drives, networks and other storage media and
                destroy all copies of the Software in your possession or under
                your control, and all rights granted hereunder shall revert,
                without notice, to and be vested in Licensor.
              </li>
            </ol>

            <p>
              YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, YOU UNDERSTAND
              THIS AGREEMENT, AND YOU UNDERSTAND THAT, BY CONTINUING THE
              SOFTWARE USE, BY LOADING OR RUNNING THE SOFTWARE, YOU AGREE TO BE
              BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. YOU FURTHER
              AGREE THAT, EXCEPT FOR WRITTEN, SEPARATE AGREEMENTS, IF ANY,
              BETWEEN LICENSOR AND YOU, THIS AGREEMENT IS A COMPLETE AND
              EXCLUSIVE STATEMENT OF THE RIGHTS AND LIABILITIES OF THE PARTIES
              HERETO RELATING TO THE SUBJECT MATTER HEREOF. THIS AGREEMENT
              SUPERSEDES ALL PRIOR ORAL AGREEMENTS, PROPOSALS, OR
              UNDERSTANDINGS, AND ANY OTHER COMMUNICATIONS, IF ANY, BETWEEN
              LICENSOR AND YOU RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.
            </p>
            <br />
            <p>
              If you have any questions concerning this license, you may contact
              in writing OPTIMUM FUTURIST TECH PRIVATE LIMITED at
              support@danfebooks.com.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}
