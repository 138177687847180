import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import Layout from '../layout';
import Footer from '../components/Footer/index';
import PageHeader from '../components/UserAgreement/pageHeader';
import Content from '../components/UserAgreement/content';

const privacyPolicy = () => {
  const intl = useIntl();
  return (
    <Layout>
      <Helmet title={intl.formatMessage({ id: 'title' })} />
      <PageHeader />
      <Content />
      <Footer />
    </Layout>
  );
};

export default privacyPolicy;
